import PropTypes from 'prop-types';
import React from 'react';
import { navigate } from 'gatsby';

function PrivateRoute({ component: Component, navUrl, isAllowed, ...rest }) {
  if (!isAllowed) {
    navigate(navUrl);
    return <span />;
  }
  return <Component {...rest} />;
}

PrivateRoute.propTypes = {
  component: PropTypes.elementType,
  location: PropTypes.object,
  navUrl: PropTypes.string,
  isAllowed: PropTypes.bool
};

export default PrivateRoute;
